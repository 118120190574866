import { Box, CardMedia, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import RightDrawer from "../../../rightDrawer/rightDrawer";
import SelectedAvailableBeachChair from "../../selectedAvailableBeachChair/selectedAvailableBeachChair";
import AvailabilitiesHeader from "../../availabilitiesHeader/availabilitiesHeader";
import { useParams } from "react-router";
import { useLocationData } from "../../../../utils/customHooks/useLocationData";
import {
  GoogleMap,
  Marker,
  OverlayView,
  OverlayViewF,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import * as turf from "@turf/turf";
import { SBColor } from "../../../SBComponents/styles/SBStyles";
import {
  useSection,
  useVendorSections,
} from "../../../bookingsList/vendorBooking/hooks/useSection";
import { useHistory } from "react-router-dom";
import { useBeachChairsAvailabilities } from "../../hooks/useBeachChairsAvailabilities";
import { useAvailabilitiesDatesStore } from "../../hooks/useAvailabilitiesDatesStore";
import { useBookingsFiltersPersists } from "../../../../utils/customHooks/useBookingsFiltersPersists";
import { useDispatch } from "react-redux";
import { routerRequest } from "../../../../store/reducers/routerReducer";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import { Reason } from "../../../../models/availability/EAvailabilityTime";
import { EColor } from "../../../../models/beachChairs/EColors";
import lock from "../../../../assets/lock.png";
import { EventBusy } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useBookingInProgressStore } from "../../../bookingsList/store/useBookingZustand";
import { useOpenBookingsInPeriodBeachChairIds } from "../../hooks/useOpenBookingsInPeriodBeachChairIds";
import { useEditBeachChairsMapStore } from "./hooks/useEditBeachChairsMapStore";
import logo_sbl from "../../../../assets/editPin.png";
import { useUpdateBeachChairLocationMapMutation } from "./hooks/useUpdateBeachChairLocationMapMutation";
import { useGetVendor } from "../../../vendor";

export const getCentroid = (geoJson: object) => {
  const polygon = turf.polygon((geoJson as any).coordinates);
  return turf.centroid(polygon);
};

const getTimeStyle = (timeEnum: string) => {
  switch (timeEnum) {
    case "boxBackgroundFree":
      return {
        backgroundColor: customStyle.whiteColor,
      };
    case "boxBackgroundNoon":
      return {
        background: `linear-gradient(180deg, ${customStyle.whiteColor} 50%, ${customStyle.lightGray} 50%)`,
      };
    case "boxBackgroundAfterNoon":
      return {
        background: `linear-gradient(180deg, ${customStyle.lightGray} 50%, ${customStyle.whiteColor} 50%)`,
      };
    case "boxBackgroundAllDay":
      return {
        backgroundColor: customStyle.gray,
        color: "white",
      };
  }
};

const containerStyle = {
  height: "calc(100vh - 2*70px - 91px)",
};

const setZoomLevel = 18;

const availabilitiesBeachChairsMap = () => {
  const { draggable, setDraggable } = useEditBeachChairsMapStore();
  useEffect(() => {
    setDraggable(false);
  }, []);
  const updateBeachChairLocation = useUpdateBeachChairLocationMapMutation();
  const { t } = useTranslation(["common"]);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(0);
  const [markers, setMarkers] = useState<object[]>();
  const { sectionId } = useParams<{ sectionId: string }>();
  const [rightDrawerState, setRightDrawerState] = useState(false);
  const [selectedBeachChairId, setSelectedBeachChairId] = useState(0);
  const { data: sectionData } = useSection(+sectionId);
  const { data: sectionsPolygonsData } = useVendorSections(
    sectionData?.vendorId
  );
  const history = useHistory();
  const { data: vendor } = useGetVendor(sectionData?.vendorId);
  const { locationData } = useLocationData(sectionData?.locationId);
  const { availabilitiesDateRange } = useAvailabilitiesDatesStore();
  const { data: availableBeachChairs } = useBeachChairsAvailabilities(
    +sectionId,
    availabilitiesDateRange[0]?.format("YYYY-MM-DD"),
    availabilitiesDateRange[1]?.format("YYYY-MM-DD")
  );
  const { bookingInProgressBeachChairsIds } = useBookingInProgressStore();
  const { data: bookings, isLoading: isOpenIdsLoading } =
    useOpenBookingsInPeriodBeachChairIds(
      availabilitiesDateRange[0]?.add(-1, "day").toISOString(),
      availabilitiesDateRange[1]?.add(2, "day").toISOString()
    );
  const openIds = useMemo(
    () => bookings?.map((item) => item.beachChairId) ?? [],
    [bookings]
  );

  const { setColumnFiltersPersits } = useBookingsFiltersPersists();
  const dispatch = useDispatch();
  useEffect(() => {
    let beachChairMarkers: object[] = [];
    availableBeachChairs?.forEach((row) => {
      (row.rowData as any).forEach((beachChairs) => {
        if (
          Object.keys(beachChairs.geoJson).length &&
          beachChairs.geoJson.coordinates[0] !== 0 &&
          beachChairs.geoJson.coordinates[1] !== 0
        ) {
          beachChairMarkers.push(beachChairs);
        }
      });
    });
    setMarkers(beachChairMarkers);
  }, [availableBeachChairs]);

  const geoJson = useMemo(() => {
    return sectionsPolygonsData?.find((section) => section.id === +sectionId)
      ?.geoJson;
  }, [sectionsPolygonsData, sectionId]);

  const multipleSectionsGeoJson = useMemo(() => {
    return sectionsPolygonsData?.filter((section) => section.id !== +sectionId);
  }, [sectionsPolygonsData, sectionId]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: encodeURIComponent(
      "AIzaSyBWGae4Fpv0p0n-llM6Qysj4r_CvvhzIkw"
    ),
    language: "DE",
  });

  const center = useMemo(() => {
    if (geoJson) {
      const centroid = getCentroid(geoJson);
      return {
        lat: centroid.geometry.coordinates[1],
        lng: centroid.geometry.coordinates[0],
      };
    }
  }, [geoJson]);

  const path = useMemo(() => {
    return (geoJson as any)?.coordinates[0].map((coord) => {
      return { lat: coord[1], lng: coord[0] };
    });
  }, [geoJson]);

  const multipleSectionsGeoJsonPaths = useMemo(() => {
    return multipleSectionsGeoJson?.map((section) => {
      return {
        sectionId: section.id,
        path: (section.geoJson as any)?.coordinates[0].flatMap((coord) => {
          return { lat: coord[1], lng: coord[0] };
        }),
      };
    });
  }, [geoJson]);

  const onLoad = useCallback(
    function callback(map) {
      let mapBounds = new window.google.maps.LatLngBounds();
      (path as any).forEach((point) => {
        mapBounds.extend({
          lat: point.lat,
          lng: point.lng,
        });
      });
      map.fitBounds(mapBounds);
      setMap(map);
    },
    [path]
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Box
      sx={{
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        bottom: 0,
      }}
    >
      {rightDrawerState && (
        <RightDrawer state={rightDrawerState} setState={setRightDrawerState}>
          <SelectedAvailableBeachChair
            id={selectedBeachChairId}
            handleClose={() => setRightDrawerState(false)}
            locationData={locationData}
            publicReference={vendor?.publicReference}
          />
        </RightDrawer>
      )}
      <AvailabilitiesHeader />
      <Box sx={{ height: "calc(100vh - 2*70px - 91px)" }}>
        {isLoaded && !!path && !!center ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onZoomChanged={() => {
              setZoom((map as any)?.getZoom());
            }}
            options={{ minZoom: 17 }}
          >
            {draggable
              ? markers?.map((marker, index) => {
                  const beachChair = marker as any;
                  const lat = parseFloat(beachChair.geoJson.coordinates[0]);
                  const lng = parseFloat(beachChair.geoJson.coordinates[1]);
                  return (
                    <Marker
                      key={`${index}-${marker}`}
                      options={{
                        icon: {
                          url: logo_sbl,
                          scaledSize:
                            zoom <= setZoomLevel
                              ? new google.maps.Size(15, 18)
                              : new google.maps.Size(32, 38),
                        },
                      }}
                      position={{ lat, lng }}
                      draggable
                      label={
                        zoom > setZoomLevel
                          ? {
                              text: beachChair.publicNumber,
                              fontSize: "15px",
                              fontWeight: "bold",
                              fontFamily: `"NunitoMedium", monospace`,
                              color: SBColor.blue,
                              className: "marker-label",
                            }
                          : undefined
                      }
                      onDragEnd={(e) => {
                        if (e.latLng) {
                          const latiude = e.latLng.lat();
                          const longitude = e.latLng.lng();
                          beachChair.geoJson.coordinates[0] = latiude;
                          beachChair.geoJson.coordinates[1] = longitude;
                          updateBeachChairLocation.mutateAsync({
                            id: beachChair.id,
                            update: {
                              geoJson: {
                                type: "Point",
                                coordinates: [latiude, longitude],
                              },
                            },
                          });
                        }
                      }}
                    />
                  );
                })
              : markers?.map((marker, index) => {
                  const beachChair = marker as any;
                  const isInBooking =
                    bookingInProgressBeachChairsIds.includes(beachChair.id) ||
                    openIds?.includes(beachChair.id);
                  const lat = parseFloat(beachChair.geoJson.coordinates[0]);
                  const lng = parseFloat(beachChair.geoJson.coordinates[1]);
                  let box = "FREE";
                  if ((marker as any).timeEnum === "boxBackgroundAllDay") {
                    box = "BOOKING";
                  }
                  if ((marker as any).reserved) {
                    box = "RESERVED";
                  }

                  return (
                    <OverlayViewF
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      key={`${index}-${marker}`}
                      position={{
                        lat,
                        lng,
                      }}
                    >
                      <Box
                        sx={{
                          transform: "translate(-50%,-200%)",
                          display: "grid",
                          gridTemplateRows: "1px auto auto",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          ...(box === "FREE"
                            ? {
                                color: SBColor.blue,
                              }
                            : { color: SBColor.white }),
                          ...(box === "RESERVED" && {
                            color: "green",
                          }),
                        }}
                        onClick={() => {
                          if (box === "FREE") {
                            setSelectedBeachChairId((marker as any).id);
                            setRightDrawerState(!rightDrawerState);
                          }
                          if (box === "BOOKING" || box === "RESERVED") {
                            setColumnFiltersPersits([
                              {
                                id: "publicNumber",
                                value: (marker as any).publicNumber,
                              },
                              {
                                id: "start",
                                value: [
                                  availabilitiesDateRange[0]?.startOf("day"),
                                  availabilitiesDateRange[1]
                                    ?.add(1, "day")
                                    ?.endOf("day"),
                                ],
                              },
                              {
                                id: "end",
                                value: [
                                  availabilitiesDateRange[0]?.startOf("day"),
                                  availabilitiesDateRange[1]
                                    ?.add(1, "day")
                                    ?.endOf("day"),
                                ],
                              },
                            ]);
                            dispatch(
                              routerRequest({
                                path: "bookings",
                              })
                            );
                          }
                        }}
                      >
                        <Box
                          className="pin1"
                          sx={{
                            transform: `rotate(-45deg) ${
                              zoom > setZoomLevel
                                ? "translate(-10%, -60%);"
                                : "translate(40%, -110%);"
                            }`,
                            width: zoom > setZoomLevel ? "30px" : "10px",
                            height: zoom > setZoomLevel ? "30px" : "10px",
                            display: "grid",
                            background: "white",
                            cursor: "pointer",
                            border: `2px solid ${SBColor.blue}`,
                            boxShadow: `0 0 15px ${SBColor.grayTransparent}`,

                            ...(isInBooking
                              ? {
                                  backgroundColor: SBColor.orange,
                                  color: SBColor.white,
                                }
                              : getTimeStyle(beachChair.timeEnum)),
                            ...(box === "RESERVED" && {
                              backgroundColor: customStyle.whiteColor,
                              color: "green",
                              border: `2px dashed green`,
                            }),
                            ...(beachChair.reason === Reason.STOP_SALE && {
                              backgroundColor: customStyle.gray,
                              color: customStyle.whiteColor,
                              border: `2px dashed ${customStyle.mainColor}`,
                            }),
                            ...(beachChair.attributes?.color && {
                              borderTop: `2px dashed ${
                                EColor[
                                  beachChair.attributes
                                    .color as keyof typeof EColor
                                ]
                              } !important`,
                              borderRight: `2px dashed ${
                                EColor[
                                  beachChair.attributes
                                    .color as keyof typeof EColor
                                ]
                              } !important`,
                            }),
                          }}
                        />
                        <Box
                          sx={{
                            display: "grid",
                            width: "70px",
                            height: "30px",
                            gridTemplateColumns: "50% 50%",
                          }}
                        >
                          {zoom > setZoomLevel ? (
                            <Typography
                              sx={{
                                /*border: `2px solid ${SBColor.blue}`,
                        padding: "2px",
                        borderRadius: "5px",
                        background: SBColor.white,*/
                                maxWidth: "100px",
                                minWidth: "100px",
                                textAlign: "center",
                                transform: "translate(-15%, -35%)",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                fontSize: "15px",
                                fontWeight: "bolder",
                                color: SBColor.blue,
                              }}
                              data-testid="id"
                            >
                              {beachChair.publicNumber}
                            </Typography>
                          ) : null}
                          {zoom > setZoomLevel && beachChair.lockId !== null ? (
                            <CardMedia
                              sx={{
                                transform: "translate(-200%, 25%)",
                                height: "30px",
                                width: "20px",
                              }}
                              data-testid="hasLock"
                              component="img"
                              src={lock}
                            />
                          ) : (
                            <Box />
                          )}
                          {zoom > setZoomLevel &&
                          beachChair.seasonBeachChair ? (
                            <EventBusy
                              data-testid="isSeasonBeachChair"
                              sx={{
                                gridColumnStart: "2px",
                                gridColumnEnd: "2px",
                                color: customStyle.mainColor,
                                transform: "translate(115%, -65%)",
                                height: "30px !important",
                                width: "40px !important",
                              }}
                            />
                          ) : (
                            <Box />
                          )}
                        </Box>
                        {zoom > setZoomLevel ? (
                          <Typography
                            sx={{
                              transform: "translate(0, 20%)",
                              fontSize: "11px",
                              fontWeight: "bold",
                              zIndex: 999,
                            }}
                            data-testid="model"
                          >
                            {t(`shortModels.${beachChair.model}` as any)}
                          </Typography>
                        ) : null}
                      </Box>
                    </OverlayViewF>
                  );
                })}
            <Polygon
              path={path}
              options={{
                strokeColor: SBColor.blue,
                fillColor: SBColor.orange,
                strokeOpacity: 0.78,
                strokeWeight: 1,
                fillOpacity: 0.5,
              }}
            />
            {multipleSectionsGeoJsonPaths?.map((section, index) => (
              <Polygon
                key={index}
                onClick={() => {
                  history.push(
                    `/availabilities/map/${section.sectionId}/beachChairs`
                  );
                }}
                path={section.path}
                options={{
                  strokeColor: SBColor.orange,
                  fillColor: SBColor.blue,
                  strokeOpacity: 0.78,
                  strokeWeight: 1,
                  fillOpacity: 0.5,
                }}
              />
            ))}
          </GoogleMap>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default availabilitiesBeachChairsMap;
